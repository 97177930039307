import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RehauDevicesService } from '../../services/devices.services';

@Component({
    selector: 'rh-devices-list',
    templateUrl: './devices-list.component.html',
})


export class RehauDevicesListComponent implements OnInit {
    devices = [];
    device;
    lastUpdated;
    safeGuardChecked = true;
    staticMetrics = [
        [
            13,
            3.9,
            125,
            21.5
        ],
        [
            15,
            4.1,
            133,
            23.7
        ],
        [
            17,
            5.5,
            148,
            24.4
        ],
    ]

    index = 0;

    @Output() onDeactivateSafeGuard = new EventEmitter();

    constructor(
        private devicesService: RehauDevicesService
    ) {
        this.devicesService.refresh.subscribe(res => {
            this.updateDevice();
        });
    }

    ngOnInit() {
        // this.devicesService.getDevices().then(devices => {
        //     console.log(devices);
        // });
        this.getDevice();
    }

    getDevice() {
        this.devicesService.getDevice('SafeGuard').then(device => {
            this.device = Object.assign(device.data, {});
            this.lastUpdated = (new Date().toString());
            this.lastUpdated = this.lastUpdated.substring(0, this.lastUpdated.indexOf(':') + 6);
        });
    }

    deactivateSafeGuard(event) {
        this.safeGuardChecked = !this.safeGuardChecked;
        this.onDeactivateSafeGuard.emit(this.safeGuardChecked);
    }

    updateDevice(){
        
        if(this.index > 2){
            this.index = 0;
        }
        for(let i = 0; i < this.staticMetrics[this.index].length; i++){
            this.device.metrics[i].level = this.staticMetrics[this.index][i];
        }
        this.lastUpdated = (new Date().toString());
        this.lastUpdated = this.lastUpdated.substring(0, this.lastUpdated.indexOf(':') + 6);
        this.index++;
    }
}