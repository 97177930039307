import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { RehauHeaderComponent } from './header/header.component';
import { RehauFooterComponent } from './footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    RehauHeaderComponent,
    RehauFooterComponent
  ],
  imports: [
    BrowserModule,
    SharedModule.forRoot(),
    RouterModule.forRoot([]),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
