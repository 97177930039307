import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RehauDevicesListComponent } from './components/devices-list/devices-list.component'
import { HttpModule } from '@angular/http';
import { RehauDevicesService } from './services/devices.services';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    FormsModule
  ],
  exports: [
    RehauDevicesListComponent,
  ],
  declarations: [
    RehauDevicesListComponent,
  ],
  providers: [
  ],
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        RehauDevicesService
      ]
    }
  }
}


