import { Component } from '@angular/core';
import { RehauDevicesService } from '../shared/services/devices.services';

@Component({
    selector: 'rh-header',
    templateUrl: './header.component.html',
})

export class RehauHeaderComponent {
    refreshing: any = false;
    constructor(
        private devicesServices: RehauDevicesService
    ) {
        this.devicesServices.refreshing.subscribe(refreshing => {
            this.refreshing = refreshing;
        });
    }

    refreshClick() {
        this.refreshing = true;
        setTimeout(() => {
            this.devicesServices.refresh.next(true);
            this.refreshing = false;
        }, 750);
    }
}