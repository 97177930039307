import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpHeaders } from '@angular/common/http'
import { CommonConstants } from '../constants/common'
import RJSON from 'relaxed-json';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class RehauDevicesService {
    refresh = new Subject();
    refreshing = new Subject();

    constructor(
        private http: Http
    ) { }

    getDevices() {
        return this.http.get(CommonConstants.SRV + 'devices')
            .toPromise()
            .then(res => {
                if (res) {
                    return RJSON.parse(res['_body']);
                }
            });
    }

    getDevice(deviceId) {
        this.refreshing.next(true);
        return this.http.get(CommonConstants.SRV + 'devices/' + deviceId)
            .toPromise()
            .then(res => {
                this.refreshing.next(false);
                if (res) {
                    return RJSON.parse(res['_body']);
                }
            });
    }

}